/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import url('https://fonts.googleapis.com/css?family=Georama:300,400,600&display=swap');

@font-face {   
  font-family: 'OpenSans';
  src: url('assets/fonts/OpenSans-Regular.ttf');
  font-weight: 400;
}

@font-face {   
  font-family: 'OpenSansBold';
  src: url('assets/fonts/OpenSans-Bold.ttf');
  font-weight: 700;
}

input:-internal-autofill-selected {
  background-color: rgba(255,255,255,0)!important;;
}

input:-webkit-autofill::first-line,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  -webkit-text-fill-color: #222 !important;
  font-size:16px!important;
}

ion-modal .ion-page{
  top: var(--ion-safe-area-top);
  bottom: var(--ion-safe-area-bottom);
}

.plt-tablet .audio-record{
  width: calc(100vw - 30px - max(var(--side-min-width),var(--side-max-width))) !important;
}

:root{
  color-scheme: light;
}

.sc-ion-select-popover-h ion-list.sc-ion-select-popover {
  margin-top: 0px;
  margin-bottom: 0px;
}

.pane3-toast{
  --start:unset!important;
}

.field-error {
  background: rgba(254,194,194,.3);
  background: linear-gradient(0deg, rgba(255,255,255,0) 00%, rgba(254,194,194,.3) 100%);
  padding: 3px 15px 10px;
  text-align:right;
  font-size:12px;
  color:#ec7c7c;
}

.desk-chat-info-pop{
  --width: 25%;
}

.contact-selector{
  --max-width:450px !important;
}